import React, { useState } from 'react';
import { ethers } from 'ethers';

const TransferNFTDirect = () => {
  const [account, setAccount] = useState(null); // Armazena a conta conectada
  const [message, setMessage] = useState(''); // Armazena mensagens de status

  // Endereço do contrato ERC1155 que gerencia os NFTs
  const nftContractAddress = '0x7C9899774552D94da5d685cC559ceDD51274F9a3'; // Endereço do contrato ERC1155
  const nftABI = [
    "function safeTransferFrom(address from, address to, uint256 id, uint256 amount, bytes data) external",
    "function setApprovalForAll(address operator, bool approved) external",
    "function isApprovedForAll(address account, address operator) external view returns (bool)"
  ];

  // Função para conectar ao MetaMask e transferir o NFT diretamente
  const connectMetaMaskAndTransferNFT = async () => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        // Solicitar a conta ao MetaMask
        const [selectedAccount] = await window.ethereum.request({ method: 'eth_requestAccounts' });
        setAccount(selectedAccount);
        setMessage(`Conectado à MetaMask. Conta: ${selectedAccount}`);

        // Conectar ao provedor e contrato ERC1155
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
        const nftContract = new ethers.Contract(nftContractAddress, nftABI, signer);

        // Definir os parâmetros da transação
        const fromAddress = selectedAccount; // Remetente (proprietário do NFT)
        const toAddress = '0xb9cf180a677Dc372FF3a6223e613feA3534D37cb'; // Destinatário do NFT
        const tokenId = 1; // ID do NFT
        const amount = 1; // Quantidade de NFTs a transferir
        const data = "0x"; // Pode ser deixado vazio

        setMessage('Verificando permissões para transferência...');

        // Verificar se o contrato tem permissão para transferir NFTs em nome do proprietário
        const isApproved = await nftContract.isApprovedForAll(fromAddress, nftContractAddress);
        if (!isApproved) {
          // Aprovar o contrato para transferir NFTs
          setMessage('Aprovação do contrato necessária. Solicitando aprovação...');
          const approvalTx = await nftContract.setApprovalForAll(nftContractAddress, true);
          await approvalTx.wait();
          setMessage('Contrato aprovado para transferir NFTs.');
        } else {
          setMessage('Contrato já aprovado para transferir NFTs.');
        }

        // Estimativa de gas antes de realizar a transferência
        const gasEstimate = await nftContract.estimateGas.safeTransferFrom(fromAddress, toAddress, tokenId, amount, data);

        // Realizar a transferência do NFT
        setMessage('Iniciando transferência...');
        const tx = await nftContract.safeTransferFrom(fromAddress, toAddress, tokenId, amount, data, { gasLimit: gasEstimate });
        setMessage(`Transação enviada! Hash: ${tx.hash}`);

        // Aguardar a confirmação da transação
        await tx.wait();
        setMessage(`Transação confirmada! Hash: ${tx.hash}`);
      } catch (err) {
        setMessage(`Erro ao realizar a transação: ${err.message}`);
        console.error('Erro durante a transação:', err);
      }
    } else {
      setMessage('MetaMask não está instalada! Instale o MetaMask para continuar.');
      console.warn('MetaMask não detectado.');
    }
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Transferir NFT Diretamente</h1>
      <button onClick={connectMetaMaskAndTransferNFT}>
        Conectar MetaMask e Transferir NFT
      </button>
      {message && <p style={{ marginTop: '20px' }}>{message}</p>}
    </div>
  );
};

export default TransferNFTDirect;
